import React from 'react'
import { Link } from 'react-router-dom'
import './First-banner.css'

const FirstBanner = () => {
  return (
    <>
      <div className="container-fluid">
        <h4 className='mt-4 text-center' style={{fontSize:"30px"}}>Grooming 41 MLAs to Create 40,00,000 Jobs in UP 2027-32</h4>
        <Link to="mailto:youth4employmentpolicy@gmail.com" target='_blank'>
        <div className="row p-3">
            <div className="custom-col">
              <img src="/Assets/images/first-banner-images/first-banner-1.jpeg" alt="first-banenr-image-1"  className='img-thumbnail shadow border-0'/>
            </div>
            <div className="custom-col">
              <img src="/Assets/images/first-banner-images/first-banner-2.jpeg" alt="first-banenr-image-2"  className='img-thumbnail shadow border-0'/>
            </div>
            <div className="custom-col">
              <img src="/Assets/images/first-banner-images/first-banner-3.png" alt="first-banenr-image-3"  className='img-thumbnail shadow border-0'/>
            </div>
            <div className="custom-col">
              <img src="/Assets/images/first-banner-images/first-banner-4.jpeg" alt="first-banenr-image-4"  className='img-thumbnail shadow border-0'/>
            </div>
            <div className="custom-col">
              <img src="/Assets/images/first-banner-images/first-banner-5.jpeg" alt="first-banenr-image-5"  className='img-thumbnail shadow border-0'/>
            </div>
        </div>
        </Link>

      </div>
    </>
  )
}

export default FirstBanner
